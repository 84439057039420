export const languageHashValues= {
  "GLOBAL": ".7c96d8494520a4a93a1f",
  "U4302_TRANSACTIONSDETAIL": ".869316a70315881a1d23",
  "U4400_PAYMENT": ".fcb78274ca2671d67139",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".c5d7cc737973429a6843",
  "U2117_PADAGREEMENTINVITATION": ".c6b2b697756524ad2ef1",
  "U2000_PAD": ".14e814126c64dbcae4d4",
  "U4402_INVOICEPORTALPAYMENT": ".1f1bfc2f11f8ff13f9c7",
  "U2000_BANKACCOUNTCREATION": ".43fdf04f652919938a8e",
  "U2011_LOGIN": ".2d5b6fb8309effd94d0e",
  "U2012_LOGINHELP": ".5d7509e94874b77cf07d",
  "U2014_SECURITY": ".437ca99170184d1b1b63",
  "U2086_EFTPENDINGPAYMENTS": ".05fe23d1bff420e64d13",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".2ee22c7fb39c7fc8eef4",
  "U2110_ACCOUNTCREATION": ".0a1275d8707b8e6855ed",
  "U2121_INDIVIDUALPROFILE": ".e79c0a5427f5e39223e8",
  "U2131_ORGANIZATIONPROFILE": ".84402ecdb355654f86b2",
  "U2112_ERPBINDING": ".327f07aebf6095befb12",
  "U2113_SUPPLIERINVITATION": ".3ca03b29b6b5b41db4b2",
  "U2118_RBBINDING": ".a3d2bc439df7b593062b",
  "U2132_AFFILIATION": ".effdfe5ee36a9db96e77",
  "U2133_BUSINESSLINKS": ".d7d8622a0e1c24668380",
  "U2181": ".1b9a55dc42b12dfda6e1",
  "U2211_SUBSCRIPTIONOFFER": ".b2fb81be9f1efee17bc8",
  "U2212_SUBSCRIPTIONFILTER": ".54753dd631999ae9c028",
  "U2213_SUBSCRIPTION": ".56c475b2758e1adddf0d",
  "U4111_CREDITCARDADDITION": ".3bd177880719345301cb",
  "U4112_CREDITCARDDETAIL": ".170e67e390fb7568a62b",
  "U4121_BANKACCOUNTADDITION": ".006ad1112a52cc3c0a17",
  "U4122_BANKACCOUNTDETAIL": ".a3af9452d96d2243a5e4",
  "U4303_ANONYMOUSTRANSACTION": ".30bf6a7937a2e26aa8de",
  "U4401_PAYMENTGATEWAY": ".bc00736f5bbf06db6cce",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".fce8150a95177521a903",
  "U5000_RECEIVABLE": ".4f4066ad2cbf83611289",
  "U5101_STATEMENTOFACCOUNT": ".922aeca7122f8a21a4d6",
  "U5102_INVOICE": ".0e0416083a6b6fc46e12",
  "U5103_PADAGREEMENT": ".d82cfc2db27edd630d11",
  "U5121_SENTINVOICE": ".ea08c61fb5bb30c95482",
  "U5124_PGRECEIVEDPAYMENT": ".86ddebb462a28e6446d6",
  "U5120_INVOICES": ".905314761cbe6e5069e2",
  "U5123_PADAGREEMENTCONSOLE": ".23629faca2d83113eabd",
  "U5132_PAYMENTRECONCILIATION": ".924e069f14a0583c631a",
  "U5133_BANKRECONCILIATION": ".e78671d311fed107e8a1",
  "U6112_BUSINESSLINK": ".99fad111a2c6a9216104",
  "U6113_SUPPLIERRECORD": ".335bf1b4d98df288ae3f",
  "U6211_PAYLOT": ".11280ff5395883fea8f4",
  "U6311_PURCHASE": ".6d0d642f2f4dc0dadb39",
  "U2115_AFFILIATIONINVITATION": ".b55d0fa60b4a30d0dbea",
  "U6100_PAYABLE": ".3e8077e27144c22b760a",
  "U6131_SENTPAYMENT": ".79e2c8c7a8ec0376066e",
  "U6191_SENTPAYMENTREPORT": ".dcb1a95167c54c6bacde",
  "U6221_SENTPAYMENT": ".1c72a73a2895ae02e220",
  "U6422_INTERACSENTPAYMENT": ".3ff4af70e43b9c4f0775",
  "U6531_TRANSACTIONPAYMENT": ".5e117bbf1f20d8793821",
  "U5131_RECEIVEDPAYMENT": ".9f4e12f33219ad20a1ce",
  "U5191_RECEIVEDPAYMENTREPORT": ".5f2bb2cb5ac5030219f3",
  "U2001_HOME": ".5443c5f56131bbf22426",
  "U2024_REPORTLOG": ".e11a652e42436a5afa2a",
  "U2111_INVOICEPORTALBINDING": ".ce9e4e874a7d63bfa189",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".09a2932f5c4bd59d9b1c",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".a3aaa74e8d8df021bcb8",
  "U6511_TRANSACTIONPARTY": ".cfe794e70a3d16da0c42",
  "U6212_PAYROLLPARTY": ".9c30d61455681a792c61",
  "U6121_EFTLOT": ".b4af3d2e89a7247a4cb1",
  "U6412_INTERACFILE": ".578c40df70928fc00a68",
  "U6122_EFTAPPROVAL": ".0d1cbfaf0b918bcbc59f",
  "U6512_TRANSACTIONLOT": ".3280b2f4bec9de22f600",
  "U6123_EFTAPPROVAL": ".d7f7d26aa53f55bc1db7",
  "U6123_EFTAPPROVALMOBILE": ".daf33757f173482c70b7",
  "U2012_LOGIN": ".edd0ec29190794ed6ca7",
  "U2000_PAYMENT": ".350aa25b4809d16136f4",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".7fe821fc4b96112e9683",
  "U2000_PAYMENTMODEUPDATE": ".600b914fe89b15ddada5",
  "U6114_TELUSBILLER": ".90300e63fb6234807ed2",
  "U4403_CREDITCARDTERMINAL": ".a3e32101b79fe60e931c",
  "U4404_INTERACTERMINAL": ".0a5c730ba0ff1f892410",
  "U2000_SAMPLECHEQUESELECTION": ".aa0b82eaa95c4b78f747",
  "U2023_TUTORIALVIDEO": ".eb354eae7686903e8ace",
  "U2134_ORGANIZATIONBILLING": ".2494dd48c03f71056225",
  "U6414_BANKACCOUNTWALLET": ".4324a35ff16b41ff9d6d",
  "U2022_INSTANTACCOUNTVERIFICATION": ".fb0fc46bea567d1ce9ed",
  "U6532_PENDINGTRANSACTION": ".e7e05827bbaeaf3de64b",
  "U4301_TRANSACTIONSSUMMARY": ".c295c84cf95f96af6e70",
  "U5111_SENTINVOICESTRACKING": ".5890d70d7120f89004f9",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".af48bd796f096202b6d9",
  "U2000_INTRO": ".7233af9bf3d0c213d2fc",
  "U2000_ELECTRONICSIGNATURE": ".ac688ef53b9c998d3688",
  "U2000_VERIFYEMAIL": ".7f5fe59db17adab963d0",
  "U2200_SUBSCRIPTION": ".dbbfa8566c01799bc5b9",
  "U2000_VERIFYIDENTITY": ".63cfc05320a01f634bf3",
  "U2000_ATTACHMENTVIEWER": ".55d4c1d27263e3c636b7",
  "U2212_PLANLIST": ".c1878a19db5b0c7e62d8",
  "U3002_TAXBILLSEARCHRESULT": ".885141a0c2ad832ead8d"
}